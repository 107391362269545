import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const Login = React.lazy(() =>
  import("./components/mainComponent/login/Login")
);
const ChirayuOtpVerification = React.lazy(() =>
  import("./components/mainComponent/login/ChirayuOtpVerification")
);
const DashboardMainContent = React.lazy(() =>
  import("./components/dashboard/dashboardLayout/DashboardMainContent")
);

const AllRoutes = () => {
  const  user  = useSelector((state) => state?.misReports?.data);
  var isAuthenticated = user?.status;
  const fallbackMsg = (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
  return (
    <Switch>
      <Route exact path="/login-page">
        <Suspense fallback={fallbackMsg}>
          {" "}
          <Login />{" "}
        </Suspense>
      </Route>
      <Route exact path="/otp-verification">
        <Suspense fallback={<div>Loading...</div>}>
          {" "}
          <ChirayuOtpVerification />{" "}
        </Suspense>
      </Route>

      <Route exact path="/">
        <Suspense fallback={fallbackMsg} > <Login /> </Suspense>
      </Route>
      <Route exact path="/login">
        <Suspense fallback={fallbackMsg} > <Login /> </Suspense>
      </Route>

      <Route
        path="/dashboard"
        render={() =>
          isAuthenticated ? (
            <Suspense fallback={fallbackMsg}>
              <DashboardMainContent />
            </Suspense>
          ) : (
            <Redirect to="/login-page" />
          )
        }
      ></Route>
    </Switch>
  );
};

export default AllRoutes;
