const ENV_PROD = true; // For production make it true, don't change in the local environment
let new_url;

if (ENV_PROD) {
  new_url = "https://services.chirayuayushmanharyana.in"; // Chirayu staging URL
 
} else {
  new_url = "https://stage-chirayu.sabpaisa.in";
 
  // new_url="http://192.168.34.26:8000"
}

const API_URL = {
  ////////////////////////////////////////////////////CHiRaU API
  MIS_REPORTS: `${new_url}/api/admin/transactions/`,
  REFUND_TXN: `${new_url}/api/csc-payment-refund/`,
  TRANSACT_ENQ: `${new_url}/api/admin/transactions/get-by-gateway-trans-id/`,
  LOG_IN: `${new_url}/api/user/`,
  EXPORT_MIS: `${new_url}/api/admin/`,
  GET_ALL_DISTRICT: `${new_url}/api/admin/transactions/get-district-transaction/`,
  REFRESH_URL:`${new_url}/api/token/refresh`,
  VLEWISE_REPORTS:`${new_url}/api/admin/transactions/vle-report/`
};



export const APP_ENV = ENV_PROD;

export default API_URL;
