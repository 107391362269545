import { configureStore } from "@reduxjs/toolkit";

import { misReportsSliceReducer } from "./slices/misReportsSlice";
const reducer = {
  misReports:misReportsSliceReducer, //////////////////////chirayu reducer
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
